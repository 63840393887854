var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "guanggao padT15" },
    [
      _c(
        "div",
        { staticClass: "x-bc typeList" },
        _vm._l(_vm.typeList, function (item, index) {
          return _c("div", { key: index, staticClass: "y-c item cursorP" }, [
            _c(
              "div",
              {
                staticClass: "iconBox x-c",
                class: [_vm.data.style.type == item.type ? "iconBox2" : ""],
                on: {
                  click: function ($event) {
                    return _vm.clickType(item.type, index)
                  },
                },
              },
              [
                _c("iconpark-icon", {
                  attrs: {
                    name: item.name,
                    size: "28px",
                    color:
                      _vm.data.style.type == item.type ? "#1458cc" : "#333333",
                  },
                }),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "marT10",
                style: {
                  color:
                    _vm.data.style.type == item.type ? "#1458cc" : "#333333",
                },
              },
              [_vm._v(_vm._s(item.title))]
            ),
          ])
        }),
        0
      ),
      _vm.data.style.active == 0 ? _c("el-divider") : _vm._e(),
      _vm.data.style.active == 0
        ? _c(
            "div",
            { staticClass: "addImg" },
            [
              _vm._m(0),
              _c("AccessoryUpload", {
                attrs: {
                  title: "",
                  limit: Number(10),
                  listType: "picture-card",
                  fileList: _vm.data.style.imgList,
                  fileSize: 1,
                },
                on: {
                  getFileItems: _vm.getFileItemsData,
                  delFileItems: _vm.delFileItems,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("el-divider"),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              attrs: { model: _vm.data, "label-position": "left" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.data.style.type == "roll"
                ? _c(
                    "div",
                    [
                      _c("div", { staticClass: "x-f" }, [
                        _vm._v("屏幕显示数量"),
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "图片样式", "label-width": "80px" } },
                        [
                          _c(
                            "div",
                            { staticClass: "x-bc" },
                            [
                              _c("div"),
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.data.style.imgNum,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.data.style, "imgNum", $$v)
                                    },
                                    expression: "data.style.imgNum",
                                  },
                                },
                                _vm._l(_vm.imgNumList, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("el-divider"),
                    ],
                    1
                  )
                : _vm._e(),
              ["swiper", "roll"].includes(_vm.data.style.type)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "切换速度", "label-width": "80px" } },
                    [
                      _c(
                        "div",
                        { staticClass: "x-bc" },
                        [
                          _c("div"),
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.data.style.interval,
                                callback: function ($$v) {
                                  _vm.$set(_vm.data.style, "interval", $$v)
                                },
                                expression: "data.style.interval",
                              },
                            },
                            _vm._l(_vm.intervalList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.data.style.active == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分类名称", "label-width": "100px" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.data.style.showTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "showTitle", $$v)
                            },
                            expression: "data.style.showTitle",
                          },
                        },
                        [_vm._v("显示")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.data.style.showTitle,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "showTitle", $$v)
                            },
                            expression: "data.style.showTitle",
                          },
                        },
                        [_vm._v("不显示")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.style.active == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "文本样式", "label-width": "100px" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.data.style.isBold,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "isBold", $$v)
                            },
                            expression: "data.style.isBold",
                          },
                        },
                        [_vm._v("加粗")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.data.style.isBold,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "isBold", $$v)
                            },
                            expression: "data.style.isBold",
                          },
                        },
                        [_vm._v("常规")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.style.active == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "文本对齐", "label-width": "100px" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: true },
                          model: {
                            value: _vm.data.style.alignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "alignment", $$v)
                            },
                            expression: "data.style.alignment",
                          },
                        },
                        [_vm._v("左对齐")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: false },
                          model: {
                            value: _vm.data.style.alignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.data.style, "alignment", $$v)
                            },
                            expression: "data.style.alignment",
                          },
                        },
                        [_vm._v("居中对齐")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "圆角样式", "label-width": "100px" } },
                [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("el-slider", {
                        staticStyle: { width: "300px" },
                        attrs: { max: 100, "show-input": "" },
                        on: {
                          change: function ($event) {
                            return _vm.selectborder(
                              _vm.data.style.borderRadius,
                              "radius"
                            )
                          },
                        },
                        model: {
                          value: _vm.data.style.borderRadius,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "borderRadius", $$v)
                          },
                          expression: "data.style.borderRadius",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片左右边距", "label-width": "100px" } },
                [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("el-slider", {
                        staticStyle: { width: "300px" },
                        attrs: { max: 30, "show-input": "" },
                        model: {
                          value: _vm.data.style.pageBackGauge,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "pageBackGauge", $$v)
                          },
                          expression: "data.style.pageBackGauge",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片上边距", "label-width": "100px" } },
                [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("el-slider", {
                        staticStyle: { width: "300px" },
                        attrs: { max: 30, "show-input": "" },
                        model: {
                          value: _vm.data.style.marT,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "marT", $$v)
                          },
                          expression: "data.style.marT",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片下边距", "label-width": "100px" } },
                [
                  _c(
                    "div",
                    { staticClass: "x-f" },
                    [
                      _c("el-slider", {
                        staticStyle: { width: "300px" },
                        attrs: { max: 30, "show-input": "" },
                        model: {
                          value: _vm.data.style.marB,
                          callback: function ($$v) {
                            _vm.$set(_vm.data.style, "marB", $$v)
                          },
                          expression: "data.style.marB",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "y-start" }, [
      _c("span", [_vm._v("添加图片")]),
      _c("span", { staticStyle: { color: "#ababab" } }, [
        _vm._v("建议图片尺寸宽度750，高度不限"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }