<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="微信会员支付配置" class="bg-Color">
      <template #cardContent>
        <div
          :style="{
            width: '800px',
            padding: '20px',
            height: `${contentH}px`,
            overflowY: 'auto',
          }"
        >
          <el-tabs v-model="payModeId" @tab-click="handleClick">
            <el-tab-pane
              v-for="(tab, index) in tabsColumns"
              :label="tab.title"
              :key="index"
              :name="tab.value"
            ></el-tab-pane>
          </el-tabs>
          <el-form :model="weChatForm" ref="ruleForm" label-width="200px">
            <el-form-item label="是否启用">
              <el-switch v-model="weChatForm.isStop"></el-switch>
            </el-form-item>
            <el-form-item label="是否使用门店支付通道" v-if="weChatForm.isStop">
              <el-switch v-model="weChatForm.isShopPayChannel"></el-switch>
            </el-form-item>
            <template v-if="payModeId == 6&&weChatForm.isStop&&!weChatForm.isShopPayChannel">
              <el-form-item label="支付通道方案:">
                <el-select
                  v-model="weChatForm.payChannelId"
                  class="inputW260"
                  placeholder="请选择"
                  @change="payChannelChange"
                >
                  <el-option
                    v-for="item in channelList"
                    :key="item.payChannelId"
                    :label="item.payChannelName"
                    :value="item.payChannelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div v-show="weChatForm.payChannelId">
                <el-form-item label="接入类型">
                  <el-input
                    class="inputW260"
                    v-model="weChatForm.payChannelTypeName"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="第三方支付AppID"
                  v-show="
                    weChatForm.payChannelType == 1 || weChatForm.payChannelType == 2
                  "
                  prop="merchantAppId"
                >
                  <el-input
                    v-model="weChatForm.merchantAppId"
                    placeholder="请输入第三方支付AppID"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="第三方支付密钥(Secret)"
                  v-show="
                    weChatForm.payChannelType == 1 || weChatForm.payChannelType == 2
                  "
                  prop="merchantKey"
                >
                  <el-input
                    v-model="weChatForm.merchantKey"
                    placeholder="请输入第三方支付密钥(Secret)"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="第三方支付商户号"
                  v-show="
                    weChatForm.payChannelType == 1 || weChatForm.payChannelType == 2
                  "
                  prop="merchantNo"
                >
                  <el-input
                    v-model="weChatForm.merchantNo"
                    placeholder="请输入第三方支付商户号"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="第三方支付子商户AppID"
                  v-show="weChatForm.payChannelType == 2"
                  prop="merchantSubAppId"
                >
                  <el-input
                    v-model="weChatForm.merchantSubAppId"
                    placeholder="请输入第三方支付子商户AppID"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="第三方支付子商户号"
                  v-show="weChatForm.payChannelType == 2"
                  prop="merchantSubNo"
                >
                  <el-input
                    v-model="weChatForm.merchantSubNo"
                    placeholder="请输入第三方支付子商户号"
                    disabled
                  ></el-input>
                </el-form-item>

                <el-form-item
                  label="子商户AppToken"
                  v-show="weChatForm.payChannelType == 4"
                  prop="merchantKey"
                >
                  <el-input
                    v-model="weChatForm.merchantKey"
                    placeholder="请输入子商户AppToken"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="支付宝公有密钥"
                  v-show="
                    weChatForm.payChannelType == 3 || weChatForm.payChannelType == 4
                  "
                  prop="merchantPublicKey"
                >
                  <el-input
                    v-model="weChatForm.merchantPublicKey"
                    placeholder="请输入支付宝公有密钥"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="客户私有密钥"
                  v-show="
                    weChatForm.payChannelType == 3 || weChatForm.payChannelType == 4
                  "
                  prop="merchantPrivateKey"
                >
                  <el-input
                    v-model="weChatForm.merchantPrivateKey"
                    placeholder="请输入客户私有密钥"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="商户号"
                  v-show="
                    weChatForm.payChannelType == 5 || weChatForm.payChannelType == 6
                  "
                  prop="merchantNo"
                >
                  <el-input
                    v-model="weChatForm.merchantNo"
                    placeholder="请输入商户号"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="终端号"
                  v-show="
                    weChatForm.payChannelType == 5 || weChatForm.payChannelType == 6
                  "
                  prop="merchantTerminalNo"
                >
                  <el-input
                    v-model="weChatForm.merchantTerminalNo"
                    placeholder="请输入终端号"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="授权码"
                  v-show="weChatForm.payChannelType == 5"
                  prop="merchantKey"
                >
                  <el-input
                    v-model="weChatForm.merchantKey"
                    placeholder="请输入授权码"
                    disabled
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="门店号"
                  v-show="weChatForm.payChannelType == 6"
                  prop="merchantStoreNo"
                >
                  <el-input
                    v-model="weChatForm.merchantStoreNo"
                    placeholder="请输入门店号"
                    disabled
                  ></el-input>
                </el-form-item>
              </div>
            </template>
            <template v-else-if="payModeId == 3">
              <el-form-item label="限制支付会员级别">
                <el-select
                  v-model="payVipLevelIds"
                  multiple
                  class="inputW260"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in vipRankList"
                    :key="item.vipLevelId"
                    :label="item.vipLevelName"
                    :value="item.vipLevelId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
            <el-form-item>
              <el-button type="primary" size="mini" @click="saveOrUpdate">保存</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import CardTitleCom from "@/views/components/cardTitleCom/index.vue";
import {
  payConfigDetail,
  payConfigSave,
  payConfigUpdate,
  alipayDetail,
  alipaySave,
  alipayUpdate,
  saobeiDetail,
  saobeiSave,
  saobeiUpdate,
  o2oMallPayDetail,
  o2oMallPayList,
  getDetail,
  saveOrUpdate,


  cakeGetDetail,
  cakeSaveOrUpdate,


} from "@/api/O2OMall/config/payConfig";

export default {
  name: "index",
  components: {
    CardTitleCom,
  },
  data() {
    return {
      contentH: 0,
      payModeId: "6",
      tabsColumns: [
        { title: "隼云付支付参数", value: "6" },
        // { title: "商城支付参数", value: "7" },
        { title: "会员支付参数", value: "3" },
      ],
      weChatForm: {
        // 微信
        isStop: false, // 是否启用
        isShopPayChannel:false,//是否使用门店支付通道
        merchantAppId: "",
        merchantAppSecret: "",
        merchantNo: "",
        merchantSubAppId: "",
        merchantSubNo: "",
      },
      //支付通道方案
      channelList: [],
      payVipLevelIds: [],
    };
  },
  async created() {
    await this.getChannelList();
    this.getDetail();
    this.getO2OMallPayList();
    this.handleResize();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    //自适应获取高度
    async handleResize() {
      await this.$nextTick();
      let pageH = document.getElementById("wrap").offsetHeight;
      this.contentH = pageH - 60;
    },
    async getDetail() {
      const res = await cakeGetDetail({ payModeId: Number(this.payModeId) });
      if (!res.data) return;
      if (this.payModeId == 6) {
        this.weChatForm = res.data;
        this.payChannelChange();
      } else if (this.payModeId == 7) {
        this.weChatForm = res.data;
      } else if (this.payModeId == 3) {
        this.weChatForm = res.data;
        this.payVipLevelIds = res.data.payVipLevelIds.split(",");
      }
    },
    // 支付通道
    async getChannelList() {
      const res = await o2oMallPayDetail({
        payModeId: Number(this.payModeId),
      });
      this.channelList = res.data.data;
    },
    async getO2OMallPayList() {
      const res = await o2oMallPayList();
      console.log("会员级别", res);
      this.vipRankList = res;
    },
    handleClick(e, ee) {
      this.getDetail();
    },
    //更改选中的支付通道
    async payChannelChange(payChannelId) {
      await this.$nextTick();
      if (this.channelList.length <= 0) await this.getChannelList();
      this.channelList.forEach((item) => {
        if (item.payChannelId === this.weChatForm.payChannelId) {
          const data = this.weChatForm;
          this.$set(this.weChatForm, "payChannelTypeName", item.payChannelTypeName);
          this.$set(this.weChatForm, "merchantAppId", item.merchantAppId);
          this.$set(this.weChatForm, "merchantKey", item.merchantKey);
          this.$set(this.weChatForm, "merchantNo", item.merchantNo);
          this.$set(this.weChatForm, "merchantSubAppId", item.merchantSubAppId);
          this.$set(this.weChatForm, "merchantSubNo", item.merchantSubNo);
          this.$set(this.weChatForm, "merchantPublicKey", item.merchantPublicKey);
          this.$set(this.weChatForm, "merchantPrivateKey", item.merchantPrivateKey);
          this.$set(this.weChatForm, "merchantTerminalNo", item.merchantTerminalNo);
          this.$set(this.weChatForm, "merchantStoreNo", item.merchantStoreNo);
          this.$set(this.weChatForm, "payChannelType", item.payChannelType);
          this.$set(this.weChatForm, "payChannelId", item.payChannelId);
          console.log("itemitem", item);
        }
      });
    },
    saveOrUpdate() {
      if(this.weChatForm.isStop&&!this.weChatForm.isShopPayChannel&&!this.weChatForm.payChannelId){
        this.$message.warning('请选择支付通道方案！');
        return;
      }
      cakeSaveOrUpdate({
        isStop: this.weChatForm.isStop,
        isShopPayChannel: this.weChatForm.isShopPayChannel,
        payChannelId: this.weChatForm.isStop&&!this.weChatForm.isShopPayChannel?this.weChatForm.payChannelId:'',
        payModeId: this.weChatForm.isStop&&!this.weChatForm.isShopPayChannel?Number(this.payModeId):'',
      }).then((res) => {
        this.$message.success("保存成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 10px;
  background: #e9e9e9;
  font-size: 13px;
  min-height: calc(100vh - 84px);

  .inputW260 {
    width: 400px;
  }
}
</style>
