var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data.style.type == "singleton"
      ? _c(
          "div",
          { staticClass: "singleton" },
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                style: {
                  padding:
                    _vm.data.style.marT +
                    "px " +
                    _vm.data.style.pageBackGauge +
                    "px " +
                    _vm.data.style.marB +
                    "px " +
                    _vm.data.style.pageBackGauge +
                    "px",
                },
              },
              [
                _c("el-image", {
                  style: {
                    borderRadius: _vm.data.style.borderRadius
                      ? _vm.data.style.borderRadius + "px"
                      : "0px",
                    height: _vm.data.style.imgHeight + "px",
                    width: "100%",
                  },
                  attrs: { src: item.url },
                }),
              ],
              1
            )
          }),
          0
        )
      : _vm.data.style.type == "swiper"
      ? _c(
          "div",
          {
            staticClass: "swiper",
            style: {
              padding:
                _vm.data.style.marT +
                "px " +
                _vm.data.style.pageBackGauge +
                "px " +
                _vm.data.style.marB +
                "px " +
                _vm.data.style.pageBackGauge +
                "px",
            },
          },
          [
            _c(
              "el-carousel",
              {
                attrs: {
                  interval: _vm.data.style.interval,
                  height: _vm.data.style.imgHeight + "px",
                },
              },
              _vm._l(_vm.imgList, function (item, index) {
                return _c(
                  "el-carousel-item",
                  { key: index },
                  [
                    _c("el-image", {
                      style: {
                        borderRadius: _vm.data.style.borderRadius
                          ? _vm.data.style.borderRadius + "px"
                          : "0px",
                        height: _vm.data.style.imgHeight + "px",
                        width: "100%",
                      },
                      attrs: { src: item.url },
                    }),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.data.style.type == "classification"
      ? _c(
          "div",
          {
            staticClass: "yihangyige",
            style: {
              padding:
                _vm.data.style.marT +
                "px " +
                _vm.data.style.pageBackGauge +
                "px 0px " +
                _vm.data.style.pageBackGauge +
                "px",
            },
          },
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                style: {
                  padding: "0px 0px " + _vm.data.style.marB + "px 0px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "li",
                    style: {
                      borderRadius: _vm.data.style.borderRadius + "px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticClass: "image",
                      staticStyle: { display: "block", margin: "0 auto" },
                      attrs: { src: item.url },
                    }),
                    _vm.data.style.showTitle
                      ? _c(
                          "div",
                          {
                            staticStyle: { "margin-top": "10px" },
                            style: {
                              textAlign:
                                "" +
                                (_vm.data.style.alignment ? "left" : "center"),
                              textIndent:
                                "" + (_vm.data.style.alignment ? "10px" : ""),
                              fontWeight:
                                "" + (_vm.data.style.isBold ? "bold" : "400"),
                            },
                          },
                          [_vm._v("分类名称")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    ["lianghangpailie", "sanhangpailie"].includes(_vm.data.style.type)
      ? _c(
          "div",
          {
            class:
              _vm.data.style.type === "sanhangpailie"
                ? "sanhangpailie"
                : "lianghangpailie",
            style: {
              padding:
                _vm.data.style.marT +
                "px " +
                (_vm.data.style.pageBackGauge - _vm.data.style.pageBackGauge
                  ? 10
                  : 0) +
                "px 0px " +
                (_vm.data.style.pageBackGauge - _vm.data.style.pageBackGauge
                  ? 10
                  : 0) +
                "px",
            },
          },
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "li",
                staticStyle: { background: "#f7f7f7" },
                style: {
                  marginBottom: _vm.data.style.marB + "px",
                  flex:
                    _vm.data.style.type == "lianghangpailie"
                      ? "0 0 calc(100% /2 - " +
                        (_vm.data.style.pageBackGauge + 20) +
                        "px"
                      : "0 0 calc(100% /3 - " +
                        (_vm.data.style.pageBackGauge + 30) +
                        "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: { background: "#fff", padding: "10px" },
                    style: {
                      borderRadius: _vm.data.style.borderRadius
                        ? _vm.data.style.borderRadius + "px"
                        : "0px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticClass: "image",
                      staticStyle: { display: "block", margin: "0 auto" },
                      attrs: { src: item.url },
                    }),
                    _vm.data.style.showTitle
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-bottom": "10px",
                              "margin-top": "10px",
                            },
                            style: {
                              textAlign:
                                "" +
                                (_vm.data.style.alignment ? "left" : "center"),
                              fontWeight:
                                "" + (_vm.data.style.isBold ? "bold" : "400"),
                            },
                          },
                          [_vm._v("分类名称")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.data.style.type === "datuxianshi"
      ? _c(
          "div",
          {
            staticClass: "datuxianshi",
            style: {
              padding:
                _vm.data.style.marT +
                "px " +
                _vm.data.style.pageBackGauge +
                "px 0px " +
                _vm.data.style.pageBackGauge +
                "px",
            },
          },
          _vm._l(_vm.imgList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                style: {
                  padding: "0px 0px " + _vm.data.style.marB + "px 0px",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "li",
                    style: {
                      borderRadius: _vm.data.style.showTitle
                        ? _vm.data.style.borderRadius +
                          "px " +
                          _vm.data.style.borderRadius +
                          "px 0 0 "
                        : _vm.data.style.borderRadius + "px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticClass: "image",
                      staticStyle: { display: "block", margin: "0 auto" },
                      style: {
                        borderRadius: _vm.data.style.showTitle
                          ? _vm.data.style.borderRadius +
                            "px " +
                            _vm.data.style.borderRadius +
                            "px 0 0 "
                          : _vm.data.style.borderRadius + "px",
                      },
                      attrs: { src: item.url },
                    }),
                  ],
                  1
                ),
                _vm.data.style.showTitle
                  ? _c(
                      "div",
                      {
                        staticClass: "title",
                        style: {
                          borderRadius:
                            "0 0 " +
                            _vm.data.style.borderRadius +
                            "px " +
                            _vm.data.style.borderRadius +
                            "px",
                          textAlign:
                            "" + (_vm.data.style.alignment ? "left" : "center"),
                          fontWeight:
                            "" + (_vm.data.style.isBold ? "bold" : "400"),
                          background: "#fff",
                        },
                      },
                      [_vm._v("分类名称")]
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.data.style.type === "hengxianggundong"
      ? _c(
          "div",
          { staticClass: "swipeBox x-bc" },
          [
            _c(
              "el-scrollbar",
              _vm._l(_vm.imgList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    style: {
                      width:
                        "calc(100% - " + _vm.data.style.pageBackGauge + "px)",
                      padding:
                        _vm.data.style.marT +
                        "px " +
                        _vm.data.style.pageBackGauge +
                        "px 0px " +
                        _vm.data.style.pageBackGauge +
                        "px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: { width: "120px", height: "120px" },
                      style: {
                        borderRadius: _vm.data.style.borderRadius
                          ? _vm.data.style.borderRadius + "px"
                          : "0px",
                      },
                      attrs: { src: item.url },
                    }),
                    _vm.data.style.showTitle
                      ? _c(
                          "div",
                          {
                            style: {
                              textAlign:
                                "" +
                                (_vm.data.style.alignment ? "left" : "center"),
                              fontWeight:
                                "" + (_vm.data.style.isBold ? "bold" : "400"),
                              padding: _vm.data.style.marB + "px 0px 0px 0px",
                            },
                          },
                          [_vm._v("分类名称")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }