var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("topOperatingButton", {
        attrs: { isSubmitAddBtn: false, isAuditBillBtn: false },
        on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.douYinProductForm,
            rules: _vm.rules,
            "label-width": "130px",
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT20" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "券方案编号",
                          prop: "product.productNo",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: { size: "mini", placeholder: "券方案编号" },
                          model: {
                            value: _vm.douYinProductForm.product.productNo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product,
                                "productNo",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.product.productNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "券方案名称",
                          prop: "product.productName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入券方案名称",
                          },
                          model: {
                            value: _vm.douYinProductForm.product.productName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product,
                                "productName",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.product.productName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "抖音品类",
                          prop: "product.categoryId",
                        },
                      },
                      [
                        _c("el-cascader", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            options: _vm.douYinCategory,
                            props: _vm.defaultProp,
                            filterable: "",
                            clearable: "",
                          },
                          on: { change: _vm.categoryChange },
                          model: {
                            value: _vm.douYinProductForm.product.categoryId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product,
                                "categoryId",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.product.categoryId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "商品类型",
                          prop: "product.productType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              size: "mini",
                              placeholder: "请选择商品类型",
                              disabled:
                                _vm.douYinProductForm.product.productId &&
                                _vm.douYinProductForm.product
                                  .douyinProductIdOld,
                            },
                            model: {
                              value: _vm.douYinProductForm.product.productType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.douYinProductForm.product,
                                  "productType",
                                  $$v
                                )
                              },
                              expression:
                                "douYinProductForm.product.productType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "团购券", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "代金券", value: 11 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "商品来源",
                          prop: "product.productSourceType",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              size: "mini",
                              placeholder: "请选择商品来源",
                              disabled:
                                _vm.douYinProductForm.product.productId &&
                                _vm.douYinProductForm.product
                                  .douyinProductIdOld,
                            },
                            model: {
                              value:
                                _vm.douYinProductForm.product.productSourceType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.douYinProductForm.product,
                                  "productSourceType",
                                  $$v
                                )
                              },
                              expression:
                                "douYinProductForm.product.productSourceType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "系统创建", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "抖音来客创建", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.douYinProductForm.product.productSourceType == 2
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "抖音商品ID",
                              prop: "product.douyinProductId",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入抖音商品ID",
                                disabled:
                                  _vm.douYinProductForm.product.productId &&
                                  _vm.douYinProductForm.product
                                    .douyinProductIdOld,
                              },
                              model: {
                                value:
                                  _vm.douYinProductForm.product.douyinProductId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product,
                                    "douyinProductId",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.douyinProductId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  [
                    _vm.douYinProductForm.product.productType == 1
                      ? _c(
                          "el-form-item",
                          {
                            staticClass: "recPersonNum marT10",
                            attrs: { label: "建议用餐人数" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "x-f marT5" },
                              [
                                !_vm.recPersonRadio
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "marR50",
                                        attrs: { size: "mini" },
                                        model: {
                                          value:
                                            _vm.douYinProductForm.product
                                              .recPersonNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.douYinProductForm.product,
                                              "recPersonNum",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "douYinProductForm.product.recPersonNum",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 1 } },
                                          [_vm._v("单人餐")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 2 } },
                                          [_vm._v("双人餐")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 3 } },
                                          [_vm._v("三人餐")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 4 } },
                                          [_vm._v("四人餐")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.recPersonRadio
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "marR50",
                                        attrs: { size: "mini" },
                                        on: {
                                          change: _vm.recPersonNumVirtualChange,
                                        },
                                        model: {
                                          value: _vm.recPersonNumVirtual,
                                          callback: function ($$v) {
                                            _vm.recPersonNumVirtual = $$v
                                          },
                                          expression: "recPersonNumVirtual",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 1 } },
                                          [_vm._v("单人餐")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 2 } },
                                          [_vm._v("双人餐")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 3 } },
                                          [_vm._v("三人餐")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { border: "", label: 4 } },
                                          [_vm._v("四人餐")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      border: "",
                                      label: 5,
                                      size: "mini",
                                    },
                                    on: { change: _vm.recPersonRadioChange },
                                    model: {
                                      value: _vm.recPersonRadio,
                                      callback: function ($$v) {
                                        _vm.recPersonRadio = $$v
                                      },
                                      expression: "recPersonRadio",
                                    },
                                  },
                                  [_vm._v("自定义")]
                                ),
                              ],
                              1
                            ),
                            _vm.recPersonRadio
                              ? _c(
                                  "div",
                                  { staticClass: "marT5" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90px" },
                                        attrs: {
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        model: {
                                          value:
                                            _vm.douYinProductForm.product
                                              .recPersonNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.douYinProductForm.product,
                                              "recPersonNum",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "douYinProductForm.product.recPersonNum",
                                        },
                                      },
                                      _vm._l(
                                        _vm.numberDinersList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                              disabled:
                                                item.value >=
                                                _vm.douYinProductForm.product
                                                  .recPersonNumMax,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "marL10 marR10" },
                                      [_vm._v("至")]
                                    ),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "90px" },
                                        attrs: {
                                          placeholder: "请选择",
                                          size: "mini",
                                        },
                                        model: {
                                          value:
                                            _vm.douYinProductForm.product
                                              .recPersonNumMax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.douYinProductForm.product,
                                              "recPersonNumMax",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "douYinProductForm.product.recPersonNumMax",
                                        },
                                      },
                                      _vm._l(
                                        _vm.numberDinersList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                              disabled:
                                                item.value <=
                                                _vm.douYinProductForm.product
                                                  .recPersonNum,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm.douYinProductForm.product.productType == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "* 商品搭配" } },
                          [
                            _vm._l(
                              _vm.douYinProductForm.groupReqList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "regularVouchers",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "x-f marB10" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "商品组名称",
                                              "label-width": "100px",
                                              prop:
                                                "groupReqList." +
                                                index +
                                                ".groupName",
                                              rules:
                                                _vm.rules[
                                                  "groupReqList.groupName"
                                                ],
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticClass: "inputWidth marR50",
                                              attrs: {
                                                size: "mini",
                                                placeholder:
                                                  "请输入商品组名称，最多输入10个字符",
                                                maxlength: "10",
                                              },
                                              model: {
                                                value: item.groupName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "groupName",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.groupName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "最多可选",
                                              "label-width": "80px",
                                              prop:
                                                "groupReqList." +
                                                index +
                                                ".optionCount",
                                              rules:
                                                _vm.rules[
                                                  "groupReqList.optionCount"
                                                ],
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "120px" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                  size: "mini",
                                                },
                                                on: {
                                                  change:
                                                    _vm.optionalMostChange,
                                                },
                                                model: {
                                                  value: item.optionCount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "optionCount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.optionCount",
                                                },
                                              },
                                              _vm._l(
                                                item.matchGoodsList,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "goodsTips" }, [
                                      _vm._v(
                                        " 商品组名称中不可出现“任选N”“X选N”字样，当商品组的可选范围是“几选1”或“全部可选”时，不可配置重复选 "
                                      ),
                                    ]),
                                    _vm._l(
                                      item.skuPartsList,
                                      function (goodsItem, goodsIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: goodsIndex,
                                            staticClass: " marB10",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "x-f" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "groupReqList." +
                                                        index +
                                                        ".skuPartsList." +
                                                        goodsIndex +
                                                        ".goodsId",
                                                      rules:
                                                        _vm.rules[
                                                          "skuPartsList.goodsId"
                                                        ],
                                                    },
                                                  },
                                                  [
                                                    _c("SelectRemote", {
                                                      staticClass: "marR10",
                                                      staticStyle: {
                                                        width: "180px",
                                                      },
                                                      attrs: {
                                                        option: _vm.$select({
                                                          key: "listGoods",
                                                          option: {
                                                            option: {
                                                              remoteBody: {
                                                                goodsTypes: [1],
                                                              },
                                                            },
                                                          },
                                                        }).option,
                                                      },
                                                      on: {
                                                        selectChange: function (
                                                          v,
                                                          obj
                                                        ) {
                                                          return _vm.selectChangeGoods(
                                                            v,
                                                            obj,
                                                            index,
                                                            goodsIndex
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          goodsItem.goodsId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            goodsItem,
                                                            "goodsId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "goodsItem.goodsId",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("el-input-number", {
                                                  staticStyle: {
                                                    width: "120px",
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    "controls-position":
                                                      "right",
                                                    min: 1,
                                                    max: 99,
                                                  },
                                                  model: {
                                                    value: goodsItem.unitQty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        goodsItem,
                                                        "unitQty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "goodsItem.unitQty",
                                                  },
                                                }),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "groupReqList." +
                                                        index +
                                                        ".skuPartsList." +
                                                        goodsIndex +
                                                        ".unitId",
                                                      rules:
                                                        _vm.rules[
                                                          "skuPartsList.unitId"
                                                        ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticClass: "marR10",
                                                        staticStyle: {
                                                          width: "120px",
                                                        },
                                                        attrs: {
                                                          placeholder:
                                                            "商品单位",
                                                          size: "mini",
                                                        },
                                                        model: {
                                                          value:
                                                            goodsItem.unitId,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              goodsItem,
                                                              "unitId",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "goodsItem.unitId",
                                                        },
                                                      },
                                                      _vm._l(
                                                        goodsItem.units,
                                                        function (unitItem) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: unitItem.unitId,
                                                              attrs: {
                                                                label:
                                                                  unitItem.unitName,
                                                                value:
                                                                  unitItem.unitId,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "0",
                                                      prop:
                                                        "groupReqList." +
                                                        index +
                                                        ".skuPartsList." +
                                                        goodsIndex +
                                                        ".douYinSalePrice",
                                                      rules:
                                                        _vm.rules[
                                                          "skuPartsList.douYinSalePrice"
                                                        ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "120px",
                                                      },
                                                      attrs: {
                                                        size: "mini",
                                                        placeholder:
                                                          "请输入价格",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.optionalMostChange,
                                                      },
                                                      model: {
                                                        value:
                                                          goodsItem.douYinSalePrice,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            goodsItem,
                                                            "douYinSalePrice",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "goodsItem.douYinSalePrice",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "marL10 marR20",
                                                  },
                                                  [_vm._v("元")]
                                                ),
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteRow(
                                                        item.skuPartsList,
                                                        goodsIndex,
                                                        item
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm.isMeasure
                                              ? _c(
                                                  "div",
                                                  { staticClass: "x-f marT10" },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "50px",
                                                          prop:
                                                            "groupReqList." +
                                                            index +
                                                            ".skuPartsList." +
                                                            goodsIndex +
                                                            ".douYinSalePrice",
                                                          label: "约",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "80px",
                                                          },
                                                          attrs: {
                                                            size: "mini",
                                                            placeholder: "尺寸",
                                                          },
                                                          model: {
                                                            value:
                                                              goodsItem
                                                                .attrList[0]
                                                                .attr_value.num,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                goodsItem
                                                                  .attrList[0]
                                                                  .attr_value,
                                                                "num",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "goodsItem.attrList[0].attr_value.num",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "groupReqList." +
                                                            index +
                                                            ".skuPartsList." +
                                                            goodsIndex +
                                                            ".unitId",
                                                        },
                                                      },
                                                      [
                                                        _c("SelectLocal", {
                                                          staticClass: "marR20",
                                                          staticStyle: {
                                                            width: "120px",
                                                          },
                                                          attrs: {
                                                            placeholder: "单位",
                                                            option: {
                                                              data: _vm.sizeUnits,
                                                              value: "value",
                                                              label: "label",
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              goodsItem
                                                                .attrList[0]
                                                                .attr_value
                                                                .unit,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                goodsItem
                                                                  .attrList[0]
                                                                  .attr_value,
                                                                "unit",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "goodsItem.attrList[0].attr_value.unit",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "groupReqList." +
                                                            index +
                                                            ".skuPartsList." +
                                                            goodsIndex +
                                                            ".unitId",
                                                        },
                                                      },
                                                      [
                                                        _c("SelectLocal", {
                                                          attrs: {
                                                            placeholder:
                                                              "请选择",
                                                            multiple: true,
                                                            option: {
                                                              data: _vm.specList,
                                                              value: "value",
                                                              label: "label",
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              goodsItem
                                                                .attrList[1]
                                                                .attr_value
                                                                .cake_type,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                goodsItem
                                                                  .attrList[1]
                                                                  .attr_value,
                                                                "cake_type",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "goodsItem.attrList[1].attr_value.cake_type",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isWeight
                                              ? _c(
                                                  "div",
                                                  { staticClass: "x-f marT10" },
                                                  [
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "50px",
                                                          prop:
                                                            "groupReqList." +
                                                            index +
                                                            ".skuPartsList." +
                                                            goodsIndex +
                                                            ".douYinSalePrice",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          staticStyle: {
                                                            width: "80px",
                                                          },
                                                          attrs: {
                                                            size: "mini",
                                                            placeholder:
                                                              "每份重量",
                                                          },
                                                          model: {
                                                            value:
                                                              goodsItem
                                                                .attrList[0]
                                                                .attr_value.num,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                goodsItem
                                                                  .attrList[0]
                                                                  .attr_value,
                                                                "num",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "goodsItem.attrList[0].attr_value.num",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "groupReqList." +
                                                            index +
                                                            ".skuPartsList." +
                                                            goodsIndex +
                                                            ".unitId",
                                                        },
                                                      },
                                                      [
                                                        _c("SelectLocal", {
                                                          staticClass: "marR20",
                                                          staticStyle: {
                                                            width: "120px",
                                                          },
                                                          attrs: {
                                                            placeholder: "单位",
                                                            option: {
                                                              data: _vm.sizeUnitsWeight,
                                                              value: "value",
                                                              label: "label",
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              goodsItem
                                                                .attrList[0]
                                                                .attr_value
                                                                .unit,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                goodsItem
                                                                  .attrList[0]
                                                                  .attr_value,
                                                                "unit",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "goodsItem.attrList[0].attr_value.unit",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          "label-width": "0",
                                                          prop:
                                                            "groupReqList." +
                                                            index +
                                                            ".skuPartsList." +
                                                            goodsIndex +
                                                            ".unitId",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                "包装方式",
                                                              size: "mini",
                                                            },
                                                            model: {
                                                              value:
                                                                goodsItem
                                                                  .attrList[1]
                                                                  .attr_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    goodsItem
                                                                      .attrList[1],
                                                                    "attr_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "goodsItem.attrList[1].attr_value",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.specListWeight,
                                                            function (item) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: item.value,
                                                                  attrs: {
                                                                    label:
                                                                      item.label,
                                                                    value: item,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    _c("div", { staticClass: "x-bc" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "fontS14B cursorP",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addMatchGoods(
                                                item.skuPartsList,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-plus",
                                          }),
                                          _c("span", [_vm._v("添加单品")]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "text",
                                                disabled: index == 0,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.groupPosition(
                                                    "top",
                                                    _vm.douYinProductForm
                                                      .groupReqList,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("上移")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "marR20",
                                              attrs: {
                                                type: "text",
                                                disabled:
                                                  index >=
                                                  _vm.douYinProductForm
                                                    .groupReqList.length -
                                                    1,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.groupPosition(
                                                    "bottom",
                                                    _vm.douYinProductForm
                                                      .groupReqList,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("下移")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteRow(
                                                    _vm.douYinProductForm
                                                      .groupReqList,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除组")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  2
                                )
                              }
                            ),
                            _c(
                              "div",
                              { staticClass: "collocationGroup x-bc" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      "共" +
                                        _vm.groupReqListLength +
                                        "组，" +
                                        _vm.skuPartsListLength +
                                        "个单品，总价值 (用户侧划线价) " +
                                        _vm.skuPartsPriceLength +
                                        " 元"
                                    )
                                  ),
                                ]),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.addGroup },
                                  },
                                  [_vm._v("再加一组")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.douYinProductForm.product.productType == 1
                              ? "SKU名称"
                              : "代金券名称",
                          prop: "sku.skuName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入商品名称",
                          },
                          model: {
                            value: _vm.douYinProductForm.sku.skuName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.sku,
                                "skuName",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.sku.skuName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "库存数量", prop: "limitType" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.douYinProductForm.sku.limitType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.douYinProductForm.sku,
                                  "limitType",
                                  $$v
                                )
                              },
                              expression: "douYinProductForm.sku.limitType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("限库存"),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("不限库存"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.douYinProductForm.sku.limitType == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "总库存", prop: "sku.stockQty" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth marR10",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入总库存",
                              },
                              model: {
                                value: _vm.douYinProductForm.sku.stockQty,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.sku,
                                    "stockQty",
                                    $$v
                                  )
                                },
                                expression: "douYinProductForm.sku.stockQty",
                              },
                            }),
                            _c("span", [_vm._v("份")]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.douYinProductForm.sku.limitType == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "可用库存", prop: "sku.availQty" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth marR10",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入可用库存",
                              },
                              model: {
                                value: _vm.douYinProductForm.sku.availQty,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.sku,
                                    "availQty",
                                    $$v
                                  )
                                },
                                expression: "douYinProductForm.sku.availQty",
                              },
                            }),
                            _c("span", [_vm._v("份")]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "原价金额", prop: "sku.originAmount" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth marR10",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入原价金额",
                          },
                          model: {
                            value: _vm.douYinProductForm.sku.originAmount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.sku,
                                "originAmount",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.sku.originAmount",
                          },
                        }),
                        _c("span", [_vm._v("元")]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "顾客实际需支付",
                          prop: "sku.actualAmount",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth marR10",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入顾客实际需支付",
                          },
                          model: {
                            value: _vm.douYinProductForm.sku.actualAmount,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.sku,
                                "actualAmount",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.sku.actualAmount",
                          },
                        }),
                        _c("span", [_vm._v("元")]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label:
                            _vm.douYinProductForm.product.productType == 1
                              ? "* 套餐图"
                              : "* 代金券头图",
                        },
                      },
                      [
                        _c("upload-picture-cropper", {
                          attrs: {
                            limit:
                              _vm.douYinProductForm.product.productType == 1
                                ? 5
                                : 1,
                          },
                          model: {
                            value: _vm.douYinProductForm.product.imageList,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product,
                                "imageList",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.product.imageList",
                          },
                        }),
                        _vm.douYinProductForm.product.productType == 1
                          ? _c("span", { staticClass: "sizeColor" }, [
                              _vm._v(
                                "第1张建议选用高清优质图提升客流，上传2~5张，每张图片不得超过2M，建议分辨率为375*280px，比例为4:3 "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.douYinProductForm.product.productType == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "* 菜品图" } },
                          [
                            _c("upload-picture-cropper", {
                              attrs: { limit: _vm.dishesGoodsLimit },
                              model: {
                                value:
                                  _vm.douYinProductForm.product.dishesImageList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product,
                                    "dishesImageList",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.dishesImageList",
                              },
                            }),
                            _c("span", { staticClass: "sizeColor" }, [
                              _vm._v(
                                "美观的菜品图能提升用户的购买欲望。每张图片不得超过2M，建议分辨率为375*280px，图片比例为4:3 "
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.douYinProductForm.product.productType == 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "* 环境图" } },
                          [
                            _c("upload-picture-cropper", {
                              attrs: { limit: 10 },
                              model: {
                                value:
                                  _vm.douYinProductForm.product
                                    .environmentImageList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product,
                                    "environmentImageList",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.environmentImageList",
                              },
                            }),
                            _c("span", { staticClass: "sizeColor" }, [
                              _vm._v(
                                "良好的就餐环境也是用户的优先选择，最多可上传10张。每张图片不得超过2M，建议分辨率为375*280px，比例为4:3 "
                              ),
                            ]),
                            _c("div", { staticClass: "collocationGroup" }, [
                              _c("div", { staticClass: "y-start" }, [
                                _c("span", { staticClass: "sizeColor" }, [
                                  _vm._v(
                                    " 1.图片清晰美观，不包含第三方水印、无拉伸变形无白边；图片内有关限制条款文字必须清晰可见，不得过小过偏 "
                                  ),
                                ]),
                                _c("span", { staticClass: "sizeColor" }, [
                                  _vm._v(
                                    " 2.图片素材中不得涉及和商家无关的第三方主体或品牌信息；不得使用仿冒官方的词汇或logo元素，如“抖音同款”、“官方推荐”等 "
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "* 适用门店" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.douYinProductForm.product.shopIdType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.douYinProductForm.product,
                                  "shopIdType",
                                  $$v
                                )
                              },
                              expression:
                                "douYinProductForm.product.shopIdType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "0" } }, [
                              _vm._v("全部门店"),
                            ]),
                            _c("el-radio", { attrs: { label: "1" } }, [
                              _vm._v("指定门店"),
                            ]),
                          ],
                          1
                        ),
                        _c("div", [
                          _vm.douYinProductForm.product.shopIdType == "1"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "marB10",
                                      attrs: { type: "primary", size: "mini" },
                                      on: { click: _vm.getDistribution },
                                    },
                                    [_vm._v("选择门店")]
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      ref: "multipleTable",
                                      staticStyle: { width: "700px" },
                                      attrs: {
                                        "tooltip-effect": "dark",
                                        data: _vm.douYinProductForm.product
                                          .shops,
                                        border: "",
                                        "max-height": "300px",
                                        height: "300px",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "序号",
                                          align: "center",
                                          prop: "userId",
                                          width: "80",
                                          type: "index",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "门店编号",
                                          align: "center",
                                          prop: "shopNo",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "门店名称",
                                          align: "center",
                                          prop: "shopName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _vm.isMeasure &&
                    _vm.douYinProductForm.product.productType == 1
                      ? _c("el-form-item", { attrs: { label: "商品备注" } }, [
                          _c(
                            "div",
                            { staticClass: "y-start" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.douYinProductForm.product
                                        .canFreePacking,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.douYinProductForm.product,
                                        "canFreePacking",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "douYinProductForm.product.canFreePacking",
                                  },
                                },
                                [_vm._v("可免费包装")]
                              ),
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.douYinProductForm.product
                                        .canFreeLettering,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.douYinProductForm.product,
                                        "canFreeLettering",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "douYinProductForm.product.canFreeLettering",
                                  },
                                },
                                [_vm._v("可免费在蛋糕上写字")]
                              ),
                              _c(
                                "div",
                                [
                                  _c("span", { staticClass: "marR20" }, [
                                    _vm._v("含基础配件"),
                                  ]),
                                  _c("SelectLocal", {
                                    staticStyle: { width: "200px" },
                                    attrs: {
                                      placeholder: "单位",
                                      multiple: true,
                                      option: {
                                        data: _vm.cakeBasicPartList,
                                        value: "value",
                                        label: "label",
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.douYinProductForm.product
                                          .cakeBasicPart.accessory_list,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.douYinProductForm.product
                                            .cakeBasicPart,
                                          "accessory_list",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "douYinProductForm.product.cakeBasicPart.accessory_list",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.douYinProductForm.product.productType == 1
                      ? _c("el-form-item", { attrs: { label: "赠品信息" } }, [
                          _c(
                            "div",
                            { staticClass: "regularVouchers" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "20px" },
                                  attrs: { label: "是否包含赠品" },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.douYinProductForm.product
                                            .freebieInfo.has_freebie,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.douYinProductForm.product
                                              .freebieInfo,
                                            "has_freebie",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "douYinProductForm.product.freebieInfo.has_freebie",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("包含")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("不包含")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.douYinProductForm.product.freebieInfo
                                .has_freebie
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                          },
                                          attrs: { label: "赠品名称" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              size: "mini",
                                              placeholder: "请输入",
                                              maxlength: "60",
                                              "show-word-limit": "",
                                            },
                                            model: {
                                              value:
                                                _vm.douYinProductForm.product
                                                  .freebieInfo.freebie_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.douYinProductForm.product
                                                    .freebieInfo,
                                                  "freebie_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "douYinProductForm.product.freebieInfo.freebie_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                          },
                                          attrs: { label: "赠品库存状态" },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value:
                                                  _vm.douYinProductForm.product
                                                    .freebieInfo.has_stock,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.douYinProductForm
                                                      .product.freebieInfo,
                                                    "has_stock",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "douYinProductForm.product.freebieInfo.has_stock",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { attrs: { label: true } },
                                                [_vm._v("有库存")]
                                              ),
                                              _c(
                                                "el-radio",
                                                { attrs: { label: false } },
                                                [_vm._v("无库存")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                          },
                                          attrs: { label: "赠品预留库存总量" },
                                        },
                                        [
                                          _c("el-input-number", {
                                            attrs: {
                                              "controls-position": "right",
                                              min: 1,
                                              max: 9999,
                                            },
                                            model: {
                                              value:
                                                _vm.douYinProductForm.product
                                                  .freebieInfo.total_stock_num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.douYinProductForm.product
                                                    .freebieInfo,
                                                  "total_stock_num",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "douYinProductForm.product.freebieInfo.total_stock_num",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                          },
                                          attrs: { label: "赠品活动期:" },
                                        },
                                        [_vm._v(" 与商品可消费日期一致 ")]
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "兑换规则" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              autosize: {
                                                minRows: 2,
                                                maxRows: 10,
                                              },
                                              type: "textarea",
                                              placeholder:
                                                "请输入赠品兑换规则和注意事项",
                                              maxlength: "500",
                                              "show-word-limit": "",
                                            },
                                            model: {
                                              value:
                                                _vm.douYinProductForm.product
                                                  .freebieInfo
                                                  .exchange_rule_desc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.douYinProductForm.product
                                                    .freebieInfo,
                                                  "exchange_rule_desc",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "douYinProductForm.product.freebieInfo.exchange_rule_desc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "售卖信息" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放渠道" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.douYinProductForm.product.showChannel,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product,
                                "showChannel",
                                $$v
                              )
                            },
                            expression: "douYinProductForm.product.showChannel",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("不限制"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("仅直播间售卖"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "商品售卖日期" } }, [
                    _c(
                      "div",
                      { staticClass: "regularVouchers" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "售卖日期",
                              "label-width": "80px",
                              prop: "product.soldDatetime",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              staticClass: "marR10",
                              attrs: {
                                "unlink-panels": "",
                                size: "mini",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.datetimeChange(
                                    $event,
                                    "soldStartTime"
                                  )
                                },
                              },
                              model: {
                                value:
                                  _vm.douYinProductForm.product.soldDatetime,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product,
                                    "soldDatetime",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.soldDatetime",
                              },
                            }),
                            _c("div", { staticClass: "sizeColor" }, [
                              _vm._v(
                                " 顾客可以在这个时间内购买团购，超过这个日期，团购活动自动下线 "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marT15",
                            attrs: { label: "自动延期", "label-width": "80px" },
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value: _vm.douYinProductForm.product.autoRenew,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product,
                                    "autoRenew",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.autoRenew",
                              },
                            }),
                            _c("div", { staticClass: "sizeColor" }, [
                              _vm._v(
                                " 售卖时间到期前一天，商品售卖日期和顾客可消费日期自动延长30天（历史已售订单不受影响，可随时关闭) "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "交易规则" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "顾客信息设置" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.douYinProductForm.product.customerReservedInfo
                                .allow_tel,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product
                                  .customerReservedInfo,
                                "allow_tel",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.product.customerReservedInfo.allow_tel",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("不需要"),
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("需要顾客手机号"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "顾客可消费日期" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value:
                              _vm.douYinProductForm.product.useDate
                                .use_date_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product.useDate,
                                "use_date_type",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.product.useDate.use_date_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("指定天数"),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("指定日期"),
                          ]),
                        ],
                        1
                      ),
                      _vm.douYinProductForm.product.useDate.use_date_type == 2
                        ? _c(
                            "div",
                            { staticClass: "regularVouchers" },
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("自购买次日起"),
                              ]),
                              _c("el-input-number", {
                                staticClass: "marR10",
                                attrs: {
                                  "controls-position": "right",
                                  min: 1,
                                  max: 360,
                                  size: "mini",
                                },
                                model: {
                                  value:
                                    _vm.douYinProductForm.product.useDate
                                      .day_duration,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.douYinProductForm.product.useDate,
                                      "day_duration",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "douYinProductForm.product.useDate.day_duration",
                                },
                              }),
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("天"),
                              ]),
                              _c("span", [_vm._v("购买当日默认可用")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.douYinProductForm.product.useDate.use_date_type == 1
                        ? _c(
                            "div",
                            { staticClass: "regularVouchers" },
                            [
                              _c("el-date-picker", {
                                staticClass: "marR10",
                                attrs: {
                                  "unlink-panels": "",
                                  size: "mini",
                                  type: "daterange",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.datetimeChange(
                                      $event,
                                      "use_start_date"
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.douYinProductForm.product.useDate
                                      .datetime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.douYinProductForm.product.useDate,
                                      "datetime",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "douYinProductForm.product.useDate.datetime",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "顾客不可消费日期" } }, [
                    _c(
                      "div",
                      [
                        _c("el-checkbox", {
                          model: {
                            value:
                              _vm.douYinProductForm.product.canNoUseDate.enable,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product.canNoUseDate,
                                "enable",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.product.canNoUseDate.enable",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.douYinProductForm.product.canNoUseDate.enable
                      ? _c("div", { staticClass: "y-start" }, [
                          _c(
                            "div",
                            { staticClass: "x-f marB10" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "marR10",
                                  staticStyle: {
                                    width: "100px",
                                    "text-align": "right",
                                  },
                                },
                                [_vm._v(" 每周不可用 ")]
                              ),
                              _c("SelectLocal", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  multiple: true,
                                  option: {
                                    data: _vm.weekList,
                                    value: "value",
                                    label: "label",
                                  },
                                },
                                model: {
                                  value:
                                    _vm.douYinProductForm.product.canNoUseDate
                                      .days_of_week,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.douYinProductForm.product
                                        .canNoUseDate,
                                      "days_of_week",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "douYinProductForm.product.canNoUseDate.days_of_week",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "x-f marB10" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "marR10",
                                  staticStyle: {
                                    width: "100px",
                                    "text-align": "right",
                                  },
                                },
                                [_vm._v(" 节假日不可用 ")]
                              ),
                              _c("SelectLocal", {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  multiple: true,
                                  option: {
                                    data: _vm.festivalsList,
                                    value: "value",
                                    label: "label",
                                  },
                                },
                                model: {
                                  value:
                                    _vm.douYinProductForm.product.canNoUseDate
                                      .holidays,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.douYinProductForm.product
                                        .canNoUseDate,
                                      "holidays",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "douYinProductForm.product.canNoUseDate.holidays",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "x-f marB10" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "marR10",
                                  staticStyle: {
                                    width: "100px",
                                    "text-align": "right",
                                  },
                                },
                                [_vm._v(" 指定某天不可用 ")]
                              ),
                              _c("el-date-picker", {
                                attrs: {
                                  size: "mini",
                                  type: "dates",
                                  placeholder: "选择多个日期",
                                  "value-format": "yyyy-MM-dd",
                                },
                                model: {
                                  value:
                                    _vm.douYinProductForm.product.canNoUseDate
                                      .date_list,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.douYinProductForm.product
                                        .canNoUseDate,
                                      "date_list",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "douYinProductForm.product.canNoUseDate.date_list",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "每日消费时段" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "marB15",
                          model: {
                            value:
                              _vm.douYinProductForm.product.useTime
                                .use_time_type,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product.useTime,
                                "use_time_type",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.product.useTime.use_time_type",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "y-start marT10" },
                            [
                              _c(
                                "el-radio",
                                { staticClass: "marB20", attrs: { label: 1 } },
                                [_vm._v("营业时间可用")]
                              ),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("仅指定时间可用"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm.douYinProductForm.product.useTime.use_time_type == 2
                        ? _c(
                            "div",
                            [
                              _vm._l(
                                _vm.douYinProductForm.product.useTime
                                  .time_period_list,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "x-f" },
                                    [
                                      _c("el-time-picker", {
                                        staticClass: "marR50",
                                        attrs: {
                                          size: "mini",
                                          "is-range": "",
                                          "range-separator": "至",
                                          "start-placeholder": "开始时间",
                                          "end-placeholder": "结束时间",
                                          placeholder: "选择时间范围",
                                          "value-format": "HH:mm",
                                          format: "HH:mm",
                                          step: "30",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.datetimeChange(
                                              $event,
                                              "time_period_list",
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.datetime,
                                          callback: function ($$v) {
                                            _vm.$set(item, "datetime", $$v)
                                          },
                                          expression: "item.datetime",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "fontS14B cursorP",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteRow(
                                                _vm.douYinProductForm.product
                                                  .useTime.time_period_list,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "fontS14B cursorP",
                                  on: { click: _vm.addTime },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c("span", [_vm._v("添加时间段")]),
                                ]
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "限购规则" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.douYinProductForm.sku.limitRule.is_limit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.sku.limitRule,
                                "is_limit",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.sku.limitRule.is_limit",
                          },
                        },
                        [_vm._v("限制购买")]
                      ),
                      _vm.douYinProductForm.sku.limitRule.is_limit
                        ? _c(
                            "div",
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("每人最多购买"),
                              ]),
                              _c("el-input-number", {
                                staticClass: "marR10",
                                attrs: {
                                  size: "mini",
                                  "controls-position": "right",
                                  min: 1,
                                  max: 999,
                                },
                                model: {
                                  value:
                                    _vm.douYinProductForm.sku.limitRule
                                      .total_buy_num,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.douYinProductForm.sku.limitRule,
                                      "total_buy_num",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "douYinProductForm.sku.limitRule.total_buy_num",
                                },
                              }),
                              _c("span", [_vm._v("张")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预约规则" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.douYinProductForm.product.appointment
                                .need_appointment,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product.appointment,
                                "need_appointment",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.product.appointment.need_appointment",
                          },
                        },
                        [_vm._v("到店前需要预约")]
                      ),
                      _vm.douYinProductForm.product.appointment.need_appointment
                        ? _c(
                            "div",
                            [
                              _c("span", { staticClass: "marR10" }, [
                                _vm._v("需提前"),
                              ]),
                              _vm.douYinProductForm.product.appointment
                                .ahead_time_type == 1
                                ? _c("el-input-number", {
                                    staticClass: "marR10",
                                    attrs: {
                                      size: "mini",
                                      "controls-position": "right",
                                      min: 1,
                                      max: 9999,
                                    },
                                    model: {
                                      value:
                                        _vm.douYinProductForm.product
                                          .appointment.ahead_day_num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.douYinProductForm.product
                                            .appointment,
                                          "ahead_day_num",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "douYinProductForm.product.appointment.ahead_day_num",
                                    },
                                  })
                                : _c("el-input-number", {
                                    staticClass: "marR10",
                                    attrs: {
                                      size: "mini",
                                      "controls-position": "right",
                                      min: 1,
                                      max: 9999,
                                    },
                                    model: {
                                      value:
                                        _vm.douYinProductForm.product
                                          .appointment.ahead_hour_num,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.douYinProductForm.product
                                            .appointment,
                                          "ahead_hour_num",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "douYinProductForm.product.appointment.ahead_hour_num",
                                    },
                                  }),
                              _c(
                                "el-select",
                                {
                                  staticClass: "marR10",
                                  staticStyle: { width: "70px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "mini",
                                  },
                                  model: {
                                    value:
                                      _vm.douYinProductForm.product.appointment
                                        .ahead_time_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.douYinProductForm.product
                                          .appointment,
                                        "ahead_time_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "douYinProductForm.product.appointment.ahead_time_type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "天", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "小时", value: 2 },
                                  }),
                                ],
                                1
                              ),
                              _c("span", [_vm._v("电话预约")]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "退款规则" } }, [
                    _vm._v(" ·到店核销：随时可退，过期未核销自动退 "),
                  ]),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "消费规则" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "marT20", attrs: { label: "使用规则标题" } },
                    [
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: { placeholder: "请输入使用规则标题" },
                        model: {
                          value:
                            _vm.douYinProductForm.product.notification[0].title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.douYinProductForm.product.notification[0],
                              "title",
                              $$v
                            )
                          },
                          expression:
                            "douYinProductForm.product.notification[0].title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用规则内容" } },
                    [
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: { placeholder: "请输入使用规则内容" },
                        model: {
                          value:
                            _vm.douYinProductForm.product.notification[0]
                              .content,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.douYinProductForm.product.notification[0],
                              "content",
                              $$v
                            )
                          },
                          expression:
                            "douYinProductForm.product.notification[0].content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "使用规则" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value:
                              _vm.douYinProductForm.product
                                .superimposedDiscounts,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.douYinProductForm.product,
                                "superimposedDiscounts",
                                $$v
                              )
                            },
                            expression:
                              "douYinProductForm.product.superimposedDiscounts",
                          },
                        },
                        [_vm._v("可与店内其它优惠同享，详询商家")]
                      ),
                      _vm.douYinProductForm.product.productType == 1
                        ? _c(
                            "el-checkbox",
                            {
                              model: {
                                value:
                                  _vm.douYinProductForm.product.privateRoom,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product,
                                    "privateRoom",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.privateRoom",
                              },
                            },
                            [_vm._v("可以使用包间")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.douYinProductForm.product.productType == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "堂食外带约定" } },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value:
                                      _vm.douYinProductForm.product
                                        .bringOutMeal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.douYinProductForm.product,
                                        "bringOutMeal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "douYinProductForm.product.bringOutMeal",
                                  },
                                },
                                [_vm._v("是否可以外带餐食")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.douYinProductForm.product.productType == 11
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "使用张数限制" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "marT10",
                              model: {
                                value:
                                  _vm.douYinProductForm.product.limitUseRule
                                    .is_limit_use,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.douYinProductForm.product.limitUseRule,
                                    "is_limit_use",
                                    $$v
                                  )
                                },
                                expression:
                                  "douYinProductForm.product.limitUseRule.is_limit_use",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  staticClass: "marB20",
                                  attrs: { label: false },
                                },
                                [_vm._v("不限张数")]
                              ),
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("限制张数 "),
                              ]),
                            ],
                            1
                          ),
                          _vm.douYinProductForm.product.limitUseRule
                            .is_limit_use
                            ? _c(
                                "div",
                                { staticClass: "regularVouchers" },
                                [
                                  _c("span", { staticClass: "marR10" }, [
                                    _vm._v("单次消费最多使用"),
                                  ]),
                                  _c("el-input-number", {
                                    staticClass: "marR10",
                                    attrs: {
                                      size: "mini",
                                      "controls-position": "right",
                                      min: 1,
                                      max: 999,
                                    },
                                    model: {
                                      value:
                                        _vm.douYinProductForm.product
                                          .limitUseRule.use_num_per_consume,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.douYinProductForm.product
                                            .limitUseRule,
                                          "use_num_per_consume",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "\n                douYinProductForm.product.limitUseRule.use_num_per_consume\n              ",
                                    },
                                  }),
                                  _c("span", [_vm._v("张")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "其他说明信息" } },
                    [
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: {
                          type: "textarea",
                          placeholder:
                            "请输入其他规则限制，规则需与其他商品信息前后一致，多条规则请用回车换行区分",
                          maxlength: "1000",
                          "show-word-limit": "",
                          autosize: { minRows: 4, maxRows: 6 },
                        },
                        model: {
                          value:
                            _vm.douYinProductForm.product.descriptionRichText[0]
                              .content,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.douYinProductForm.product
                                .descriptionRichText[0],
                              "content",
                              $$v
                            )
                          },
                          expression:
                            "douYinProductForm.product.descriptionRichText[0].content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }