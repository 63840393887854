var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "TablePage",
        {
          ref: "tablePage",
          on: { handleEvent: _vm.handleEvent },
          model: {
            value: _vm.options,
            callback: function ($$v) {
              _vm.options = $$v
            },
            expression: "options",
          },
        },
        [
          _c("el-table-column", {
            attrs: { slot: "expand", type: "expand", fixed: "" },
            slot: "expand",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-form",
                      {
                        staticClass: "demo-table-expand",
                        attrs: { "label-position": "left", inline: "" },
                      },
                      [
                        _c("el-form-item", { attrs: { label: "订单ID" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.orderId))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "订单类型" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.orderTypeName))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "商品名称" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.skuName))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "数量" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.skuQty))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "原价金额" } }, [
                          _c("span", [
                            _vm._v(_vm._s(props.row.originalAmount)),
                          ]),
                        ]),
                        _c("el-form-item", { attrs: { label: "实付金额" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.payAmount))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "订单状态" } }, [
                          _c("span", [
                            _vm._v(_vm._s(props.row.orderStatusName)),
                          ]),
                        ]),
                        _c("el-form-item", { attrs: { label: "支付优惠" } }, [
                          _c("span", [
                            _vm._v(_vm._s(props.row.paymentDiscount)),
                          ]),
                        ]),
                        _c("el-form-item", { attrs: { label: "创建时间" } }, [
                          _c("span", [
                            _vm._v(_vm._s(props.row.createOrderTime)),
                          ]),
                        ]),
                        _c("el-form-item", { attrs: { label: "支付时间" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.payTime))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "联系人" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.contactName))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "联系电话" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.contactPhone))]),
                        ]),
                        _c("el-form-item", { attrs: { label: "用户ID" } }, [
                          _c("span", [_vm._v(_vm._s(props.row.openId))]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        attrs: {
                          "tooltip-effect": "dark",
                          data: props.row.itemList,
                          border: "",
                          "max-height": "300",
                          height: "300",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "序号",
                            align: "center",
                            prop: "userId",
                            width: "80",
                            type: "index",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "券ID",
                            align: "center",
                            prop: "certificateId",
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "状态",
                            align: "center",
                            prop: "itemStatusName",
                            "min-width": "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "退款金额",
                            align: "center",
                            prop: "refundAmount",
                            "min-width": "120",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "退款时间",
                            align: "center",
                            prop: "refundTime",
                            "min-width": "155",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "券订单ID",
                            align: "center",
                            prop: "orderId",
                            "min-width": "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "更新时间",
                            align: "center",
                            prop: "itemUpdateTime",
                            "min-width": "155",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }