<template>
  <div class="wrap" v-loading="loading">
    <!-- 顶部按钮 -->
    <topOperatingButton :isSubmitAddBtn="false" :isAuditBillBtn="false" @submitForm="submitForm" @getQuit="getQuit">
    </topOperatingButton>
    <el-form ref="form" :model="douYinShopForm" :rules="rules" label-width="110px">
      <cardTitleCom cardTitle="基本信息">
        <template slot="cardContent">
          <div class="tableContent">
            <div class="x-w">
              <el-form-item label="分店" prop="shopId">
                <SelectRemote class="marR20" v-model="douYinShopForm.shopId" style="width: 250px" :option="
                    $select({
                      key: 'listDouYinShop',
                      option: {
                        option: {
                          disabled:douYinShopForm.createBy,
                          showItem: [
                            {
                              shopId: douYinShopForm.shopId,
                              shopName: douYinShopForm.shopName,
                            },
                          ],
                        },
                      },
                    }).option
                  " />
              </el-form-item>
              <el-form-item label="抖音门店名称">
                <el-input disabled size="mini" class="inputWidth" v-model="douYinShopForm.poiName"
                  placeholder="请输入抖音门店名称"></el-input>
              </el-form-item>
              <el-form-item label="门店经度">
                <el-input disabled size="mini" class="inputWidth" v-model="douYinShopForm.longitude"
                  placeholder="门店经度"></el-input>
              </el-form-item>
              <el-form-item label="门店纬度">
                <el-input disabled size="mini" class="inputWidth" v-model="douYinShopForm.latitude"
                  placeholder="门店纬度"></el-input>
              </el-form-item>
              <el-form-item label="抖音门店地址">
                <el-input disabled size="mini" style="width: 350px" v-model="douYinShopForm.poiAddress"
                  placeholder="请输入抖音门店地址"></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input style="width: 350px" v-model="douYinShopForm.remark" type="textarea"
                  placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" />
              </el-form-item>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="商家信息">
        <template slot="cardContent">
          <div class="tableContentImg y-start">
            <el-form-item label="上传营业执照" label-width="155px">
              <accessory-upload class="marB20" listType="picture-card" :limit="1" title=""
                :fileList="fileListLicenseImageUrl" @getFileItems="getFileItemsDataLogo($event, 1)"
                @delFileItems="delFileItemsLogo(1)" />
            </el-form-item>
            <el-form-item label="上传食品经营许可证" label-width="155px">
              <accessory-upload class="marB20" listType="picture-card" :limit="1" title=""
                :fileList="fileListQualificationExpiration" @getFileItems="getFileItemsDataLogo($event, 2)"
                @delFileItems="delFileItemsLogo(2)" />
            </el-form-item>
            <div class="x-x marB20">
              <el-form-item label="上传身份证" label-width="155px">
                <accessory-upload listType="picture-card" :limit="1" :isLimitMany="true" title=""
                  :fileList="fileListIdCardFrontImageUrl" @getFileItems="getFileItemsDataLogo($event, 3)"
                  @delFileItems="delFileItemsLogo(3)" />
                <div>
                  <span>点击上传</span>
                  <span class="fontS14B">人像面</span>
                </div>
              </el-form-item>
              <el-form-item label-width="50px">
                <accessory-upload listType="picture-card" :limit="1" :isLimitMany="true" title=""
                  :fileList="fileListIdCardBackImageUrl" @getFileItems="getFileItemsDataLogo($event, 4)"
                  @delFileItems="delFileItemsLogo(4)" />
                <div>
                  <span>点击上传</span>
                  <span class="fontS14B">国徽面</span>
                </div>
              </el-form-item>
            </div>

            <el-form-item label-width="170px" label="企业名称" prop="companyName">
              <el-input size="mini" class="inputWidth" v-model="douYinShopForm.companyName"
                placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="工商营业执照号" prop="licenseId">
              <el-input size="mini" class="inputWidth" v-model="douYinShopForm.licenseId"
                placeholder="请输入工商营业执照号"></el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="工商营业执照有效期" prop="licenseExpiration">
              <el-date-picker class="" size="mini" v-model="douYinShopForm.licenseExpiration" type="date"
                placeholder="请选择工商营业执照有效期" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label-width="170px" label="法人姓名" prop="legalPersonName">
              <el-input size="mini" class="inputWidth" v-model="douYinShopForm.legalPersonName"
                placeholder="请输入法人姓名"></el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="法人身份证号 " prop="idCardNo">
              <el-input size="mini" class="inputWidth" v-model="douYinShopForm.idCardNo"
                placeholder="请输入法人身份证号 "></el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="法人身份证有效期" prop="idCardExpiration">
              <el-date-picker class="" size="mini" v-model="douYinShopForm.idCardExpiration" type="date"
                placeholder="请选择法人身份证有效期" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label-width="170px" label="食品经营许可证有效期" prop="qualificationExpiration">
              <el-date-picker class="" size="mini" v-model="douYinShopForm.qualificationExpiration" type="date"
                placeholder="请选择食品经营许可证有效期" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label-width="170px" label="联系人" prop="contactName">
              <el-input size="mini" class="inputWidth" v-model="douYinShopForm.contactName"
                placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="联系人电话" prop="contactPhone">
              <el-input size="mini" class="inputWidth" v-model="douYinShopForm.contactPhone"
                placeholder="请输入联系人电话"></el-input>
            </el-form-item>
            <el-form-item label-width="170px" label="主体类型" prop="licenseType">
              <el-radio-group v-model="douYinShopForm.licenseType">
                <el-radio :label="91">企业</el-radio>
                <el-radio :label="92">个体工商户</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import AccessoryUpload from "@/views/components/accessoryUpload/index.vue";
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import {
  douYinShopSave, //新增抖音门店
  getShopDetail, //获取抖音门店详情
  updateShop, //修改抖音门店
} from "@/api/O2OThird/douyin/shop"; //抖音
export default {
  name: "DouyinShopDetail",
  components: {
    cardTitleCom,
    AccessoryUpload,
    topOperatingButton,
    SelectRemote,
  },
  data() {
    return {
      //工商营业执证URL回显
      fileListLicenseImageUrl: [],
      //食品经营许可证URL回显
      fileListQualificationExpiration: [],
      //身份证前面URL回显
      fileListIdCardFrontImageUrl: [],
      //身份证后面URL回显
      fileListIdCardBackImageUrl: [],
      //大盒子遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      //plus会员方案表单
      douYinShopForm: {
        shopRangeType: 0,
        shopIds: [],
        carouselUrls: [],
        carouselSecond: 5,
        carouselSizeType: 1,
        posImageNo: "111",
        licenseType: 91,
      },
      //plus会员方案表单校验
      rules: {
        shopId: [
          {
            required: true,
            message: "分店不能为空",
            trigger: ["blur", "change"],
          },
        ],
        companyName: [
          {
            required: true,
            message: "企业名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        licenseId: [
          {
            required: true,
            message: "工商营业执照号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        licenseExpiration: [
          {
            required: true,
            message: "工商营业执照有效期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        legalPersonName: [
          {
            required: true,
            message: "法人姓名不能为空",
            trigger: ["blur", "change"],
          },
        ],
        idCardExpiration: [
          {
            required: true,
            message: "法人身份证有效期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        qualificationExpiration: [
          {
            required: true,
            message: "食品经营许可证有效期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // contactName: [
        //   {
        //     required: true,
        //     message: "联系人不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        // contactPhone: [
        //   {
        //     required: true,
        //     message: "联系人电话不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        idCardNo: [
          {
            required: true,
            message: "法人身份证号不能为空",
            trigger: ["blur", "change"],
          },
        ],
        licenseType: [
          {
            required: true,
            message: "主体类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 重置数据
      vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.shopId) {
          //获取单据详情
          const { data } = await getShopDetail(vm.$route.query.shopId);
          //单据赋值
          vm.douYinShopForm = data;
          //工商营业执证URL回显
          vm.fileListLicenseImageUrl = [
            {
              name: "licenseImageUrl",
              url: vm.douYinShopForm?.licenseImageUrl,
            },
          ];
          //食品经营许可证URL回显
          vm.fileListQualificationExpiration = [
            {
              name: "qualificationImageUrl",
              url: vm.douYinShopForm?.qualificationImageUrl,
            },
          ];
          //身份证前面URL回显
          vm.fileListIdCardFrontImageUrl = [
            {
              name: "idCardFrontImageUrl",
              url: vm.douYinShopForm?.idCardFrontImageUrl,
            },
          ];
          //身份证后面URL回显
          vm.fileListIdCardBackImageUrl = [
            {
              name: "idCardBackImageUrl",
              url: vm.douYinShopForm?.idCardBackImageUrl,
            },
          ];
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.douYinShopForm));
        }
      } else {
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.douYinShopForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.douYinShopForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.douYinShopForm.posImageId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.douYinShopForm));
    }
    next();
  },
  methods: {
    /** 提交按钮 */
    async submitForm() {
      if (!this.douYinShopForm.licenseImageUrl)
        return this.$message.error("营业执照不能为空");
      if (!this.douYinShopForm.qualificationImageUrl)
        return this.$message.error("食品经营许可证不能为空");
      if (!this.douYinShopForm.idCardFrontImageUrl)
        return this.$message.error("身份证人像面不能为空");
      if (!this.douYinShopForm.idCardBackImageUrl)
        return this.$message.error("身份证国徽面不能为空");
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.douYinShopForm.createTime) {
            await updateShop(this.douYinShopForm);
            this.$message.success("修改门店成功");
          } else {
            await douYinShopSave(this.douYinShopForm);
            this.$message.success("新增门店成功");
          }
        }
      });
    },
    //上传阿里图库 Logo
    getFileItemsDataLogo(value, inUrl) {
      if (inUrl == 1) {
        //工商营业执证
        this.douYinShopForm.licenseImageUrl = value.accessUrl;
      } else if (inUrl == 2) {
        //食品经营许可证
        this.douYinShopForm.qualificationImageUrl = value.accessUrl;
      } else if (inUrl == 3) {
        //身份证前面
        this.douYinShopForm.idCardFrontImageUrl = value.accessUrl;
      } else if (inUrl == 4) {
        //身份证后面
        this.douYinShopForm.idCardBackImageUrl = value.accessUrl;
      }
    },
    //删除阿里图库 Logo
    delFileItemsLogo(inUrl) {
      if (inUrl == 1) {
        //工商营业执证
        this.douYinShopForm.licenseImageUrl = undefined;
      } else if (inUrl == 2) {
        //食品经营许可证
        this.douYinShopForm.qualificationImageUrl = undefined;
      } else if (inUrl == 3) {
        //身份证前面
        this.douYinShopForm.idCardFrontImageUrl = undefined;
      } else if (inUrl == 4) {
        //身份证后面
        this.douYinShopForm.idCardBackImageUrl = undefined;
      }
    },

    // 表单重置
    reset() {
      //工商营业执证URL回显
      this.fileListLicenseImageUrl = [];
      //食品经营许可证URL回显
      this.fileListQualificationExpiration = [];
      //身份证前面URL回显
      this.fileListIdCardFrontImageUrl = [];
      //身份证后面URL回显
      this.fileListIdCardBackImageUrl = [];
      this.douYinShopForm = {
        shopRangeType: 0,
        shopIds: [],
        carouselUrls: [],
        carouselSecond: 5,
        carouselSizeType: 1,
        posImageNo: "111",
        licenseType: 91,
      };
      this.resetForm("form");
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/O2OThird/douyin/shop" });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //内容
  .tableContent {
    width: 100%;
    padding: 6px 10px 5px 10px;
  }
  //图片管理
  .tableContentImg {
    padding: 20px 0 10px 30px;
  }
  //输入框
  ::v-deep .el-input--medium .el-input__inner {
    height: 28px;
    line-height: 28px;
  }
  ::v-deep .el-form-item {
    padding: 0;
    margin-bottom: 15px;
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
